import { request } from "./utils";

export async function fetchJSON(url: string) {
  try {
    let response = await fetch(url);
    return await response.json();
  } catch (error) {
    console.log(error);
  }
}

export async function answerQuestion(question: string) {
  let requestBody = [];

  let userQuestion = {
    'question': question
  }

  for (var property in userQuestion) {
    var encodedKey = encodeURIComponent(property);
    var encodedValue = encodeURIComponent(userQuestion[property]);
    requestBody.push(encodedKey + "=" + encodedValue);
  }

  requestBody = requestBody.join("&")

  try {
    let response = await fetch("https://uclchatservices.azurewebsites.net/api/chat", {
      method: "POST",
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
      },
      body: requestBody,
    })
    return await response.json();
  } catch (error) {
    console.log(error)
  }
}

export function clearChat(chatContainer: HTMLDivElement) {
  let emptyChatMessage = document.createElement('div');
  emptyChatMessage.id = "js-empty-chat-message";
  emptyChatMessage.classList.add("chat-message", "chat-message--response");
  emptyChatMessage.innerHTML = "What can I help you with today?"


  chatContainer.innerHTML = "";
  chatContainer.append(emptyChatMessage);
}

export function chatFormSubmit(formData: FormData, chatContainer: HTMLDivElement, endpoint: string) {
  let query = String(formData.get('search'));
  // Push the query to chat and put a 'responding' div for the bot.
  let userMessage = document.createElement('div');
  let apiMessage = document.createElement('div');
  let emptyChatMessage = document.getElementById('js-empty-chat-message');

  if (emptyChatMessage) {
    emptyChatMessage.remove();
  }


  // Set classes for styling etc.
  userMessage.classList.add("chat-message", "chat-message--user")
  apiMessage.classList.add("chat-message", "chat-message--response")

  // Set initial content
  userMessage.innerHTML = query
  apiMessage.innerHTML = "<span class='responding'>Thinking...</span>"

  // Append to Chat
  chatContainer.append(userMessage);
  chatContainer.append(apiMessage);

  // Ensure they are in view
  window.scrollTo(0, document.body.scrollHeight);

  // Construct the API call, and fire
  let response = answerQuestion(query);

  response.then(answer => {
    apiMessage.classList.remove('responding')
    apiMessage.innerHTML = answer.text
    window.scrollTo(0, document.body.scrollHeight);
  })
}