// olli imports
import { olli } from "olli";
import { VegaLiteAdapter } from "olli-adapters"; // Or VegaAdapter, or ObservableAdapter

// vega-lite imports
import * as vega from "vega";
import * as vegaLite from "vega-lite";

import { fetchJSON } from './search-functions';

// Cohort Data
let cohortData = fetchJSON('https://opensheet.elk.sh/1UASpyY5jUr3ba_l0uBlC-h8D3dACMXj0Nk66IBId8hw/cohortstimelinedata');
let cohortTimeline: any

cohortData.then(data => {
  data.forEach((element) => {
    element.type = 'Cohorts Started'
  });
  cohortTimeline = data;
  checkCanRun()
})

// Survey Data
let surveyData = fetchJSON('https://opensheet.elk.sh/1UASpyY5jUr3ba_l0uBlC-h8D3dACMXj0Nk66IBId8hw/surveytimelinedata');
let surveyTimeline: any

surveyData.then(data => {
  data.forEach((element) => {
    element.type = 'Survey Waves'
  });
  surveyTimeline = data;
  checkCanRun()
})

// Article Data
let articleData = fetchJSON('https://opensheet.elk.sh/1UASpyY5jUr3ba_l0uBlC-h8D3dACMXj0Nk66IBId8hw/articletimelinedata');
let articleTimeline: any

articleData.then(data => {
  data.forEach((element) => {
    element.type = 'Studies Published'
  });
  articleTimeline = data;
  checkCanRun()
})

function checkCanRun() {
  if(cohortTimeline && articleTimeline && surveyTimeline){
    let graphData = cohortTimeline.concat(articleTimeline).concat(surveyTimeline);
    let noDataBefore2000: Array<T> = []

    graphData.forEach((element) => {
      let year = Number(element.year)
      if(year > 2000) {
        noDataBefore2000.push(element)
      }
    });
    runTimeline(noDataBefore2000);
  }
}

/*  --------------------- Runtime ---------------------- */
   

function runTimeline(graphData: any){

  let spec = {
    $schema: "https://vega.github.io/schema/vega-lite/v5.json",
    title: "Datasets including AT over time",
    description: "Datasets including AT over time",
    width: 'container',
    height: 400,
    data: {
      "values": graphData
    },
    mark: "bar",
    config: {
      legend: {
        disable: false,
      },
    },
    encoding: {
      x: { 
        timeUnit: "year", 
        field: "year", 
        type: "temporal", 
        title: "Year"
      },
      y: { 
        field: "number of new datasets", 
        type: "quantitative",
        title: "Number of New Datasets"
      },
      color: { field: "type", type: "nominal" },
      tooltip: [
        {field: "number of new datasets", type: "quantitative"},
        {field: "type", type: "nominal"}
      ]
    },
  };

  // code to render the vega-lite chart
  const vegaSpec = vegaLite.compile(spec).spec;
  const runtime = vega.parse(vegaSpec);
  const vegaContainer = document.getElementById("vega-container");
  const view = new vega.View(runtime)
    .logLevel(vega.Warn)
    .initialize(vegaContainer)
    .renderer("svg")
    .hover()
    .run();

  VegaLiteAdapter(spec).then((olliVisSpec: any) => {
    const olliContainer = document.getElementById("olli-container");
    const olliRender = olli(olliVisSpec);
    if (olliContainer) {
      olliContainer.append(olliRender);
    }
  });
}



